import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbarside from '../component/Navbarside';
import Footer from './Footer';
import Featured from './featured/Featured';
import Chart from './chart/Chart';
import Widget from './widget/widget';
function Home() {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const Name = user?.data?.company_name;
  const user_type = user?.data?.user_type;

  return (
    <div>
      <div class="row" style={{ marginLeft: '0', marginRight: '0' }}>
        <Navbarside />
        <div class="main-content pt-0">
          <div class="container">
            <div class="page-header">
              <div>
                <h2 class="main-content-title tx-24 mg-b-5">
                  Welcome To {Name}({user_type})
                </h2>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Project Dashboard
                  </li>
                </ol>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card custom-card-home">
                  <div className="card-body">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-6 col-12">
                          <div className="card-block pt-2 pb-0 d-flex" style={{ gap: '15px' }}>
                            <Widget type="FPO" amount={120} diff={15} title="FPO" link="See all FPO" isMoney={false} />
                            <Widget type="Share Capital" amount={1050}  title="Share Capital" link="See details" isMoney={true} />
                            <Widget type="Share Holder" amount={51}  title="Share Holder" link="See details" isMoney={false} />
                          </div>
                          <div className="card-block pt-2 pb-0 d-flex" style={{ gap: '15px' }}> 
                          <Widget type="Sale" amount={1500} diff={-10} title="Sale" link="View net Sale" isMoney={true} />
                          <Widget type="Purchase" amount={2000} diff={8} title="Purchase" link="View net Purchase" isMoney={true} />
                            <Widget type="Net Profit" amount={5000} diff={12} title="Net Profit" link="See details" isMoney={true} />
                           
                          </div>
                          <div className="card-block pt-2 pb-0 d-flex" style={{ gap: '15px' }}> 
                          <Widget type="Stock" amount={300} diff={5} title="Stock" link="View all Stock" isMoney={true} />
                          <Widget type="Bank" amount={102353} diff={5} title="Bank" link="View all Stock" isMoney={true} />
                          <Widget type="Cash" amount={55000} diff={5} title="Cash" link="View all Stock" isMoney={true} />
                          </div>
                          <div className="card-block pt-2 pb-0 d-flex" style={{ gap: '15px' }}>
                          <Widget type="Sundry Debtors" amount={510}  title="Sundry Debtors" link="See details" isMoney={false} />
                          <Widget type="Sundry Creditors" amount={1550}  title="Sundry Creditors" link="See details" isMoney={false} />
                          <Widget type="Assets" amount={1550}  title="Assets" link="See details" isMoney={false} />
                             </div>
                          <div className="charts">
                            <Featured />
                            <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
                          </div>
                          <div id="Widget-line-chart" className="height-70 lineChartWidget WidgetlineChart mb-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
