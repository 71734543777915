import { LicenceCheck } from "../../store/slices/client";

export const API_BASE_URL =
  process.env.API_BASE_URL || "https://kisaan-khata-api.updateproject.com";

export const X_API_KEY="z3rlamk8xyv4r79x5wb8t35x3ot49yzg"
 // CommonImgUploadService
export const CommonImgUploadService_BASE_URL="https://updateproject.com/kisaan-khata-api/uploads"


export const API_PATHS = {
  
  // // get Expert list
  // ExperList: API_BASE_URL +'/get/expertise',

  // // Dashboard Api url 
  // dashboard :API_BASE_URL + "dashboard/admin",

  // //upload media
  // uploadImage: API_BASE_URL_WM + "/upload",
  // multiUploadImage: API_BASE_URL + "/multiUpload",


  //  /farmer_documents

  //authentication
  login: "/partner-company/login",
  partnerList: "/partner-company/list",
  partnerAdd: "/partner-company/add",
  partnerEdit: "/partner-company/get-detail",
  partnerUpdate: "/partner-company/update",

  clientList: "/clients/list",
  clientAdd: "/clients/add",
  clientEdit: "/clients/get-detail",
  clientUpdate: "/clients/update",
  CheckEmailclients :"/clients/check-email",
  CheckGSTclients :"/clients/check-gst",
  getstate:"/common/getstatelist",
  licenceList : "licence/list",
  licenceAdd : "licence/store",
  licenceEdit : "licence/get-detail",
  licenceUpdate : "licence/update",
  LicenceCheck : "licence/check",

  



  

}