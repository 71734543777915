import "./Featured.css";

const Featured = () => {
  return (
    <div className="featured d-flex flex-column align-items-center justify-content-evenly">
          <button className="btn register-link">Shareholder List</button>
          <button className="btn register-link">Share Capital</button>
          <button className="btn register-link">Equity Grant</button>
          <button className="btn register-link">Key Management Person</button>
    </div>
  );
};

export default Featured;
